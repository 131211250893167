


async function AppService(name) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name: name })
};

const domain = process.env.REACT_APP_DOMAIN_NAME || process.env.DOMAIN_NAME;
console.log("domain")
console.log(domain)

const url = `${ domain || ''}/users/save`

const data = await fetch(url, requestOptions)
.then(response => response.json())
.then(data => data);

return data;

}

export default AppService;
