import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import AppService from "./App.service";

require("dotenv").config();

function App() {
  const [name, setName] = useState("some name");
  const [response, setResponse] = useState('');

  async function submitData(e) {
    e.preventDefault();
    const data = await AppService(name);
    setResponse(JSON.stringify(data));
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <section>
          <div>
            <form onSubmit={(e) => submitData(e)}>
              <input
                type={Text}
                onChange={(evt) => setName(evt.target.value)}
              ></input>
              <button type={"submit"}>Submit</button>
            </form>
          </div>
        </section>
        <div>{response}</div>
      </header>
    </div>
  );
}

export default App;
